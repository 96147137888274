import Echo from 'laravel-echo';
 
import Pusher from 'pusher-js';
window.Pusher = Pusher;

let pusherAppKey;
let pusherAppCluster;

if (import.meta.env.VITE_APP_ENV === 'local') {
    // Usar la licencia desde .env en entorno local
    pusherAppKey = import.meta.env.VITE_PUSHER_APP_KEY
    pusherAppCluster = import.meta.env.VITE_PUSHER_APP_CLUSTER
} else {
    pusherAppKey = 'b580896c855de7646d1a'
    pusherAppCluster = 'eu'

}



window.Echo = new Echo({
    broadcaster: 'pusher',
    key: pusherAppKey,
    cluster: pusherAppCluster,
    forceTLS: true,
});

// Escuchar el estado de la conexión
window.Echo.connector.pusher.connection.bind('state_change', (state) => {
    console.log('Estado de conexión:', state);
    if (state.current === 'connected') {
        console.log('Conexión establecida con Pusher.');
    } else if (state.current === 'disconnected') {
        console.warn('Conexión con Pusher desconectada.');
    }
});

// Manejar errores de conexión
window.Echo.connector.pusher.connection.bind('error', (error) => {
    console.error('Error en la conexión con Pusher:', error);
});