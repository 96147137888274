<template>
    <div v-if="active" class="backdrop">
      <div class="spinner-container" :style="{ width: width + 'px', height: height + 'px' }">
        <div class="spinner" :style="{ borderColor: borderColor + ' transparent transparent transparent', width: width + 'px', height: height + 'px' }"></div>
        <p>{{ message }}</p>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue'
  import { defineProps } from 'vue'
  
  const props = defineProps({
    active: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      default: 'Loading'
    },
    width: {
      type: Number,
      default: 50
    },
    height: {
      type: Number,
      default: 50
    },
    color: {
      type: String,
      default: '#1D40AF'
    },
    backgroundColor: {
      type: String,
      default: '#fafafa'
    },
    lockScroll: {
      type: Boolean,
      default: true
    }
  })
  
  const borderColor = ref(props.color)
  
  // Bloquear el scroll si lockScroll es true
  const lockScroll = () => {
    if (props.lockScroll && typeof document !== 'undefined') {
      document.body.style.overflow = 'hidden'
    }
  }
  
  // Restaurar el scroll
  const unlockScroll = () => {
    if (props.lockScroll && typeof document !== 'undefined') {
      document.body.style.overflow = ''
    }
  }
  
  onMounted(() => {
    if (props.active) {
      lockScroll()
    }
  })
  
  onUnmounted(() => {
    unlockScroll()
  })
  </script>
  
  <style scoped>
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .spinner-container {
    text-align: center;
  }
  
  .spinner {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid;
    border-radius: 50%;
    animation: spin 0.5s linear infinite;
    margin-bottom: 10px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  